import React from 'react'
import { Route } from 'react-router-dom'
import loadable from '@loadable/component'
import Loading from '@/components/Loading'

import { RouterProps } from './index'

const AsyncPage = loadable(
  (props: RouterProps) => {
    document.title = props?.title || 'PBL智能专家辅助系统'
    return import(/* webpackChunkName: '[request]' */ `@/${props.component}`)
  },
  {
    fallback: <Loading />,
  },
)

const NestedRoute = (route: any) => (
  <Route
    path={route.path}
    exact={route.exact}
    render={() => (
      <AsyncPage {...route}>
        {
          route?.routes?.map((item: any) => (
            <NestedRoute key={`route-${item.path}`} {...item} />)
          )
        }
      </AsyncPage>
    )}
  />
)

export default NestedRoute
