import React, { useEffect } from 'react'
import {
  Switch, Redirect, useLocation, useHistory,
} from 'react-router-dom'
import routes, { NestedRoute } from '@/router'
import { setToken } from '@/utils/token'
import { cancel } from '@/api/fetch'
import { message } from 'antd'
import { handleEncryptId } from './utils'

const App: React.FC = () => {
  const history = useHistory()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const token = params.get('token')
  const errCode = params.get('errCode')

  useEffect(() => {
    history.listen(() => {
      cancel()
    })
  }, [])

  if (errCode) {
    const { pathname } = history?.location || {}
    const id = params.get('id')
    const errCodeObj: any = {
      '-2': 'token已过期',
      '-3': '应用未配置正确',
      '-5': '应用未找到或未配置私钥',
      '-10': '远程调用出错',
      '-11': '用户未找到或已过期',
      3: '账号已失效',
      5: '该账号不在本组织内!',
      7: '获取第三方应用配置信息失败!',
      8: '未获取到钉钉userId!',
    }
    if (id) {
      localStorage.setItem('schoolId', handleEncryptId(id))
    }
    message.error(errCodeObj[errCode], 1, () => {
      if (pathname === '/chaoxing/login') {
        if (['-11', 3, 5].includes(errCode)) {
          history.push('/login')
          return null
        }
        // eslint-disable-next-line consistent-return
        return
      }

      history.push('/login')
      return null
    })
  }

  if (token) {
    setToken(token)
    const url = window.location.href.split('?')[0]?.split('#')[1]
    const corpId = params.get('corpId')
    const code = params.get('code')
    if (!corpId) {
      history.replace(url)
    } else {
      history.replace(`${url}?corpId=${corpId}&code=${code}`)
    }
  }
  return (
    <>
      <Switch>
        {
          routes.map((item) => <NestedRoute key={item.path} {...item} />)
        }
        <Redirect to="/" exact />
      </Switch>
    </>
  )
}

export default App
