import React from 'react'
import Enum, { Item } from '@/constants/Enum'

export const KnowledgeTypeEnum = new Enum([
  { key: 1, text: '知识' },
  { key: 2, text: '技能' },
  { key: 3, text: '其他' },
])

export const AbilityType = new Enum([
  { key: '1', text: '学生自评' },
  { key: '2', text: '团队小组互评' },
  { key: '3', text: '教师评学生' },
])

// 项目类型
export const ProjectTypeEnum = new Enum([
  // { key: '54', text: 'PBL单学科项目' },
  // { key: '55', text: 'PBL跨学科项目' },
  // { key: '58', text: '设计思维项目' },
  // { key: '59', text: '数据驱动项目' },
  { key: '65', text: 'PBLwork' },
])

// 方案内容类型
interface ScaProps extends Item {
  color: string;
  tag: string;
}
export const SchemeCategory = new Enum<ScaProps>([
  {
    key: '0', text: '新增文本', color: '#FEAF9C', tag: '内容',
  },
  {
    key: '1', text: '新增视频', color: '#FFCE6A', tag: '视频',
  },
  {
    key: '2', text: '新增问卷调查', color: '#77D9C3', tag: '问卷',
  },
])

// 附件类型
export const AttachmentEnum = new Enum([
  { key: '0', text: '文件' },
  { key: '1', text: '链接' },
  { key: '2', text: '图片' },
  { key: '3', text: '视频' },
  { key: '4', text: '音频' },
])

// 角色类型
export const RoleStatusEnum = new Enum([
  { key: 1, text: '已启用' },
  { key: 2, text: '已禁用' },
])

// 收藏类型
export const FavoritesTypeEnum = new Enum([
  { key: '0', text: '方案收藏夹' },
  { key: '3', text: '项目收藏夹' },
  { key: '2', text: '工具收藏夹' },
  { key: '1', text: '视频收藏夹' },
])

// 公共标签色值
export const Color = new Enum([
  { key: 0, text: '#77D9C3' },
  { key: 1, text: '#E48050' },
  { key: 2, text: '#5DA7FC' },
  { key: 3, text: '#77D9C3' },
  { key: 4, text: '#E48050' },
  { key: 5, text: '#5DA7FC' },
  { key: 6, text: '#77D9C3' },
])

export const AccountTypeEnum = new Enum([
  { key: '0', text: '管理员' },
  { key: '8', text: '老师' },
  { key: '9', text: '专家' },
  { key: '2', text: '校级管理员' },
])

export const Sex = new Enum([
  { key: 'M', text: '男' },
  { key: 'F', text: '女' },
])

// 基本设计项类型
export const BasicColumnTypeEnum = new Enum([
  { key: '1', text: '输入框' },
  { key: '2', text: '下拉框' },
  { key: '3', text: '富文本框' },
  { key: '4', text: '弹窗' },
  { key: '5', text: '文本域' },
  { key: '6', text: '复选框' },
])

// 弹窗类型
export const PopupTypeEnum = new Enum([
  { key: '0', text: '核心知识库' },
  { key: '1', text: '本质问题库' },
  { key: '2', text: '驱动性问题库' },
])

export const RoleName = new Enum([
  { key: 0, text: '超级管理员' },
  { key: 2, text: '学校管理员' },
  { key: 8, text: '老师' },
  { key: 9, text: '专家' },
])

export const NumConversion = new Enum([
  { key: 1, text: '一' },
  { key: 2, text: '二' },
  { key: 3, text: '三' },
  { key: 4, text: '四' },
  { key: 5, text: '五' },
  { key: 6, text: '六' },
  { key: 7, text: '七' },
  { key: 8, text: '八' },
  { key: 9, text: '九' },
  { key: 10, text: '十' },
  { key: 11, text: '十一' },
  { key: 12, text: '十二' },
  { key: 13, text: '十三' },
  { key: 14, text: '十四' },
  { key: 15, text: '十五' },
  { key: 16, text: '十六' },
])

export const EvaluationType = new Enum([
  { key: 1, text: '过程评价' },
  { key: 2, text: '结果评价' },
])

// 登录方式
export const syncFlgEnum = new Enum([
  { key: '1', text: '钉钉内部应用' },
  // { key: '2', text: '企业微信' },
  { key: '3', text: '钉钉第三方应用' },
  { key: '4', text: '超星应用' },
])

export const DomainName = new Enum([
  { key: '0', text: '自定义域名' },
  { key: '1', text: '公共域名' },
])

// 学校状态
export const SchoolStatusEnum = new Enum([
  { key: '0', text: '禁用' },
  { key: '1', text: '启用' },
])
export const CustomTitle = new Enum([
  { key: '0', text: '方案培训' },
  { key: '1', text: '项目学习' },
  { key: '2', text: '工具学习' },
  { key: '3', text: '视频学习' },
])

export const SettingNav = new Enum([
  { key: '0', text: '个人资料', icon: 'icon-gerenziliao1' },
  { key: '1', text: '学习历程', icon: 'icon-xiugai' },
  { key: '2', text: '用户画像', icon: 'icon-gerenziliao1' },
  { key: '3', text: '修改密码', icon: 'icon-gerenziliao1' },
])

export const StatisticalDimensionEnum = new Enum([
  { key: '1', text: '按季度' },
  { key: '2', text: '按学期' },
  { key: '3', text: '按学年' },
])

export const LearnStatusEnum = new Enum([
  { key: '1', text: '工具学习' },
  { key: '2', text: '视频学习' },
  { key: '3', text: '方案培训' },
])

// 活动的留存形式
export const ActivityKeepEnum = new Enum([
  { key: '1', text: '图片' },
  { key: '2', text: '视频' },
])

// 活动的上课形式
export const ModeEnum = new Enum([
  { key: 1, text: '校内教学' },
  { key: 2, text: '校外实践' },
])

// 年级
export const GradeEnum = new Enum([
  { key: '1', text: '一年级' },
  { key: '2', text: '二年级' },
  { key: '3', text: '三年级' },
  { key: '4', text: '四年级' },
  { key: '5', text: '五年级' },
  { key: '6', text: '六年级' },
  { key: '7', text: '七年级' },
  { key: '8', text: '八年级' },
  { key: '9', text: '九年级' },
  { key: '10', text: '十年级' },
  { key: '11', text: '十一年级' },
  { key: '12', text: '十二年级' },
])

// 班级
export const ClassEnum = new Enum([
  { key: '1', text: '一班' },
  { key: '2', text: '二班' },
  { key: '3', text: '三班' },
  { key: '4', text: '四班' },
  { key: '5', text: '五班' },
  { key: '6', text: '六班' },
  { key: '7', text: '七班' },
  { key: '8', text: '八班' },
  { key: '9', text: '九班' },
  { key: '10', text: '十班' },
  { key: '11', text: '十一班' },
  { key: '12', text: '十二班' },
  { key: '13', text: '十三班' },
  { key: '14', text: '十四班' },
  { key: '15', text: '十五班' },
  { key: '16', text: '十六班' },
  { key: '17', text: '十七班' },
  { key: '18', text: '十八班' },
  { key: '19', text: '十九班' },
  { key: '20', text: '二十班' },
])

export const TeacherTabs = [
  {
    key: '/teacher/digital/upload',
    title: '过程成果记录',
    icon: <i className="iconfont icon-xiangji1" />,
  },
  {
    key: '/teacher/signing',
    title: '学生签到',
    icon: <i className="iconfont icon-qiandao" />,
  },
  {
    key: '/teacher/mine',
    title: '我的',
    icon: <i className="iconfont icon-yonghu" />,
  },
]

export const ParentTabs = [
  {
    key: '/benefit/project',
    title: '项目',
    icon: <i className="iconfont icon-xiangmuguanli" />,
  },
  {
    key: '/benefit/todolist',
    title: '待办事项',
    icon: <i className="iconfont icon-qiandao" />,
  },
  {
    key: '/benefit/mine',
    title: '我的',
    icon: <i className="iconfont icon-yonghu" />,
  },
]

export const CourseMenu = new Enum([
  { key: '2', text: '课堂记录' },
  { key: '1', text: '课程资料' },
  { key: '4', text: '公益时申报' },
  // { key: '5', text: '课程点评' },
])

// 图片审核状态
export const VerifyStatusEnum = new Enum([
  { key: '2', text: '通过' },
  { key: '3', text: '驳回' },
])

// 公益时审核状态
export const DeclareStatusEnum = new Enum([
  { key: '1', text: '通过' },
  { key: '2', text: '驳回' },
])
