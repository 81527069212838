import React from 'react'
import './index.less'

const Index: React.FC<{
  height?: string;
}> = ({ height }) => (
  <div className="components-loading-wrap" style={{ height }}>
    <div className="spinner">
      <div className="rect1" />
      <div className="rect2" />
      <div className="rect3" />
      <div className="rect4" />
      <div className="rect5" />
    </div>
  </div>

)

export default Index
