export interface Item {
  key: string | number;
  keys?: string[];
  text: string | React.ReactNode;
  scope?: string[];
}

class Enum<T extends Item> {
  private data: T[]

  constructor(data: T[]) {
    if (data.length) {
      if (data.some(({ key, text }) => key === undefined || key === null || !text)) {
        throw new Error('key or text cannot be empty')
      }
      this.data = data
    } else {
      this.data = []
    }
  }

  get(key: string | number): T {
    return this.data.find((item) => item.key === key) || {} as T
  }

  getKey<M>(text: string | number) {
    return this.data.find((item) => item.text === text)?.key as any as M
  }

  getText(key: string | number) {
    return this.get(key).text
  }

  getTexts(keys: string[]) {
    return keys.map((key) => this.data.filter((item) => item.key === key).map((item) => item.text)[0])
  }

  getIndex(key: string | number) {
    return this.data.findIndex((item) => item.key === key)
  }

  getArray() {
    return this.data
  }
}

export default Enum
