import NestedRoute from './NestedRoute'

export interface RouterProps {
  path: string;
  component: string;
  title?: string;
  exact?: boolean;
  routes?: RouterProps[];
}

const routes: RouterProps[] = [
  {
    path: '/login',
    component: 'layouts/UserLayout',
    routes: [
      {
        path: '/login',
        component: 'pages/Login',
        exact: true,
      },
    ],
  },
  {
    path: '/parent/login',
    component: 'pages/Login/ParentLogin',
    title: '家长登录',
    exact: true,
  },
  {
    path: '/view/file',
    component: 'pages/H5Benefit/component/ViewFile',
    title: '文件预览',
    exact: true,
  },
  {
    path: '/benefit/login',
    component: 'pages/H5Benefit/Page/Login',
    title: '完善资料',
    exact: true,
  },
  {
    path: '/benefit/children',
    component: 'pages/H5Benefit/Page/childrenList',
    title: '登录',
    exact: true,
  },
  {
    path: '/benefit/course/details',
    component: 'pages/H5Benefit/Page/CourseDetails',
    title: '课程详情',
    exact: true,
  },
  {
    path: '/benefit/time',
    component: 'pages/H5Benefit/Page/BenefitTime',
    title: '我的公益时',
    exact: true,
  },
  {
    path: '/benefit/todo/detail',
    component: 'pages/H5Benefit/Page/TodoList/Detail',
    title: '实践基地',
    exact: true,
  },
  {
    path: '/benefit/project/details',
    component: 'pages/H5Benefit/Page/ProjectDetails',
    title: '项目详情',
    exact: true,
  },
  {
    path: '/benefit/bind/phone',
    component: 'pages/H5Benefit/Page/BindPhone',
    title: '绑定手机号',
    exact: true,
  },
  {
    path: '/benefit/project/view',
    component: 'pages/H5Benefit/component/VideoView',
    title: '视频预览',
    exact: true,
  },
  {
    path: '/benefit/project/view/audio',
    component: 'pages/H5Benefit/component/AudioView',
    title: '音频预览',
    exact: true,
  },
  {
    path: '/benefit',
    component: 'layouts/H5Layout',
    routes: [
      {
        path: '/benefit/project',
        component: 'pages/H5Benefit/Page/Project',
        exact: true,
        title: '我的项目',
      },
      {
        path: '/benefit/todolist',
        component: 'pages/H5Benefit/Page/TodoList',
        exact: true,
        title: '待办事项',
      },
      {
        path: '/benefit/mine',
        component: 'pages/H5Benefit/Page/Mine',
        exact: true,
        title: '我的',
      },
    ],
  },
  {
    path: '/teacher',
    component: 'layouts/TeacherH5Layout',
    routes: [
      {
        path: '/teacher/digital/upload',
        component: 'pages/TeacherH5/DigitalUpload',
        exact: true,
        title: '过程成果记录',
      },
      {
        path: '/teacher/signing',
        component: 'pages/TeacherH5/StudentSigning',
        exact: true,
        title: '学生列表',
      },
      {
        path: '/teacher/mine',
        component: 'pages/TeacherH5/Mine',
        exact: true,
        title: '我的',
      },
    ],
  },
  {
    path: '/ddHome',
    component: 'layouts/UserLayout',
    routes: [
      {
        path: '/ddHome',
        component: 'pages/ddHome',
        exact: true,
      },
      {
        path: '/ddHome/transition',
        component: 'pages/ddHome/TransitionOfH5',
        exact: true,
      },
      {
        path: '/ddHome/role/list',
        component: 'pages/ddHome/RoleList',
        exact: true,
      },
    ],
  },
  {
    path: '/test',
    component: 'test',
    exact: true,
  },
  {
    path: '/chaoxing/login',
    component: 'pages/Login/ChaoXingLogin',
    exact: true,
  },
  {
    path: '/403',
    component: 'pages/403',
    exact: true,
  },
  {
    path: '/print/pdf',
    component: 'pages/ProjectDesign/ProjectPreview/components/PrintPDF',
  },
  {
    path: '/print/word',
    component: 'pages/ProjectDesign/ProjectPreview/components/PrintWord',
  },
  {
    path: '/print/excel',
    component: 'pages/ProjectDesign/ProjectPreview/components/PrintExcel',
  },
  {
    path: '/bigScreen',
    component: 'pages/Statistics/BigScreen',
    exact: true,
  },
  {
    path: '/',
    component: 'layouts/BasicLayout',
    routes: [
      {
        path: '/',
        component: 'pages/Home',
        exact: true,
      },
      {
        path: '/template/library',
        component: 'pages/TemplateLibrary',
        exact: true,
      },
      {
        path: '/label/list',
        component: 'pages/Label/LabelList',
      },
      {
        path: '/project/design',
        component: 'pages/ProjectDesign',
        exact: true,
      },
      {
        path: '/project/preview',
        component: 'pages/ProjectDesign/ProjectPreview',
      },
      {
        path: '/video/study',
        component: 'pages/VideoSection/VideoStudy',
        exact: true,
      },
      {
        path: '/video/study/details',
        component: 'pages/VideoSection/VideoStudyDetails',
        exact: true,
      },
      {
        path: '/expertDetail/detail',
        component: 'pages/EvaluationActivities/AdminDetail',
        exact: true,
      },
      {
        path: '/scheme/assessNew',
        component: 'pages/Evaluate/AssessNew',
        exact: true,
      },
      {
        path: '/scheme/training',
        component: 'pages/Scheme/SchemeTrainingHome',
        exact: true,
      },
      {
        path: '/scheme/training/details',
        component: 'pages/Scheme/SchemeTraningDetails',
        exact: true,
      },
      {
        path: '/scheme/training/baseinfo',
        component: 'pages/Scheme/SchemeTrainingBaseInfo',
        exact: true,
      },
      {
        path: '/scheme/training/start',
        component: 'pages/Scheme/SchemeStudyHome',
        exact: true,
      },
      {
        path: '/tool/home',
        component: 'pages/Tool/ToolHome',
        exact: true,
      },
      {
        path: '/tool/detail',
        component: 'pages/Tool/ToolDetail',
        exact: true,
      },
      {
        path: '/project/home',
        component: 'pages/Project/ProjectHome',
        exact: true,
      },
      {
        path: '/videoLibrary/details',
        component: 'pages/VideoSection/VideoDetails',
        exact: true,
      },
      {
        path: '/my/favorites',
        component: 'pages/Mine/Favorites',
      },
      {
        path: '/my/setting',
        component: 'pages/Mine/Setting',
      },
      {
        path: '/scheme/home',
        component: 'pages/Scheme/SchemeHome',
        exact: true,
      },
      {
        path: '/evaluate/details',
        component: 'pages/Evaluate/AssessDescribe',
        exact: true,
      },
      {
        path: '/projectImplement/studentData/manage',
        component: 'pages/ProjectImplement/StudentDataManage',
        exact: true,
      },
      {
        path: '/projectImplement/classroom/record',
        component: 'pages/ProjectImplement/ClassroomRecord',
        exact: true,
      },
      {
        path: '/platform/benefit/statistics',
        component: 'pages/PlatformBenefitStatistics',
        exact: true,
      },
      {
        path: '/404',
        component: 'pages/404',
      },
      {
        path: '/my/Workbench',
        component: 'pages/MyWorkbench',
        routes: [
          {
            path: '/my/Workbench/scheme/training',
            component: 'pages/Scheme/SchemeTraining',
            exact: true,
          },
          {
            path: '/my/Workbench/project/list',
            component: 'pages/Project/ProjectList',
            exact: true,
          },
          {
            path: '/my/Workbench/tool/list',
            component: 'pages/Tool/ToolLibrary',
            exact: true,
          },
          {
            path: '/my/Workbench/tool/new',
            component: 'pages/Tool/ToolLibrary/component/AddTool',
            exact: true,
          },
          {
            path: '/my/Workbench/video/list',
            component: 'pages/VideoSection/VideoLibrary',
            exact: true,
          },
          {
            path: '/my/Workbench/evaluate/assess',
            component: 'pages/Evaluate/Assess',
            exact: true,
          },
          {
            path: '/my/Workbench/evaluation/activity',
            component: 'pages/EvaluationActivities/List',
            exact: true,
          },
          {
            path: '/my/Workbench/label/list',
            component: 'pages/Label/LabelList',
            exact: true,
          },
          {
            path: '/my/Workbench/role/list',
            component: 'pages/Resource/Role/RoleList',
            exact: true,
          },
          {
            path: '/my/Workbench/permission/list',
            component: 'pages/Resource/Permission/PermissionList',
            exact: true,
          },
          {
            path: '/my/Workbench/account/list',
            component: 'pages/Resource/AccountList',
            exact: true,
          },
          {
            path: '/my/Workbench/evaluation/expert/detail',
            component: 'pages/EvaluationActivities/ExpertDetail',
            exact: true,
          },
          {
            path: '/my/Workbench/evaluation/teacher/detail',
            component: 'pages/EvaluationActivities/TeacherDetail',
            exact: true,
          },
          {
            path: '/my/Workbench/evaluation/admin/detail',
            component: 'pages/EvaluationActivities/AdminDetail',
            exact: true,
          },
          {
            path: '/my/Workbench/basic/column/list',
            component: 'pages/projectDesignTemplate/BasicColumnList',
          },
          {
            path: '/my/Workbench/project/design/template/list',
            component: 'pages/projectDesignTemplate/TemplateList',
            exact: true,
          },
          {
            path: '/my/Workbench/project/template/new',
            component: 'pages/projectDesignTemplate/TemplateList/TemplateNew',
            exact: true,
          },
          {
            path: '/my/Workbench/data/statistics',
            component: 'pages/Statistics/DataList',
            exact: true,
          },
          {
            path: '/my/Workbench/data/statistics/details',
            component: 'pages/Statistics/StatisticsDetails',
            exact: true,
          },
          {
            path: '/my/Workbench/school/list',
            component: 'pages/School/SchoolList',
            exact: true,
          },
          {
            path: '/my/Workbench/school/resource',
            component: 'pages/School/SchoolResource',
            exact: true,
          },
          {
            path: '/my/Workbench/teacher/list',
            component: 'pages/TeacherList',
            exact: true,
          },
          {
            path: '/my/Workbench/integral/transform',
            component: 'pages/IntegralTransform',
            exact: true,
          },
          {
            path: '/my/Workbench/student/list',
            component: 'pages/Resource/StudentList',
            exact: true,
          },
          {
            path: '/my/Workbench/projectImplement/list',
            component: 'pages/ProjectImplement/List',
            exact: true,
          },
          {
            path: '/my/Workbench/practiceBase/list',
            component: 'pages/PracticeBase',
            exact: true,
          },
          {
            path: '/my/Workbench/benefit/system',
            component: 'pages/BenefitSystem',
            exact: true,
          },
          {
            path: '/my/Workbench/school/statistics',
            component: 'pages/SchoolStatistics',
            exact: true,
          },
          {
            path: '/my/Workbench/verityImg/list',
            component: 'pages/SchoolStatistics/VerifyImgList',
            exact: true,
          },
        ],
      },
    ],
  },
]

export default routes

export { NestedRoute }
