/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig } from 'axios'
import { message } from 'antd'
import qs from 'qs'
import { getToken } from '@/utils/token'

const { CancelToken } = axios
let cancelFetch: any = null

const fetch = axios.create({
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

interface Cofing extends AxiosRequestConfig {
  complete?: boolean; // 返回完整的结构
  message?: string | null;
}

fetch.interceptors.request.use((config: Cofing) => {
  config.headers.Authorization = getToken()
  config.cancelToken = new CancelToken((c) => {
    cancelFetch = c
  })
  return config
})

fetch.interceptors.response.use((response) => {
  if ((response as any).config.complete) {
    return response
  }
  const { code, message: reason, data } = response.data
  if (code === 200) {
    return data
  }
  if (code === 401 || code === 41601) {
    window.location.href = '#/login'
    return Promise.reject(reason)
  }
  message.error(reason)
  return Promise.reject(reason)
}, (error) => {
  if (axios.isCancel(error)) {
    return Promise.reject(error)
  }
  const { code, message: reason } = error.response?.data || {}
  const msg = reason || '网络请求异常，请稍后重试!'
  if (code === 401 || code === 41601) {
    window.location.href = '#/login'
    return Promise.reject(msg)
  }
  message.error(msg)
  return Promise.reject(msg)
})

export function cancel() {
  if (cancelFetch) {
    cancelFetch('取消请求')
  }
}

export function get<T>(url: string, params?: object, config?: Cofing) {
  return fetch.get<T, T>([url, params && qs.stringify(params)].join('?'), config).then((res) => {
    if (config?.message) {
      message.success(config?.message)
    }
    return res
  })
}

export function post<T>(url: string, params = {}, config?: Cofing) {
  return fetch.post<T, T>(url, params, config)
}

export function create<T>(url: string, params = {}, config?: Cofing) {
  return fetch.post<T, T>(url, params, config).then((res) => {
    if (config?.message !== null) {
      message.success(config?.message || '创建成功')
    }
    return res
  })
}

export function update<T>(url: string, params = {}, config?: Cofing) {
  return fetch.post<T, T>(url, params, config).then((res) => {
    if (config?.message !== null) {
      message.success(config?.message || '修改成功')
    }
    return res
  })
}

// delete 不允许使用，remove 替代
export function remove<T>(url: string, params = {}, config?: Cofing) {
  return fetch.post<T, T>(url, params, config).then((res) => {
    if (config?.message !== null) {
      message.success(config?.message || '删除成功')
    }
    return res
  })
}

// 文件上传
export function upload<T>(url: string, params: any, config?: Cofing) {
  const formData = new FormData() // 创建form对象
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key])
  })
  return fetch.post<T, T>(url, formData, config)
}
