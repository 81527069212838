export default function getEnv() {
  const url = window.location.href
  if (url.includes('localhost')) {
    return 'local'
  }
  if (url.includes('dev.') || url.includes('121.196.23.144')) {
    return 'dev'
  }
  if (url.includes('pre.')) {
    return 'pre'
  }
  return 'prod'
}
