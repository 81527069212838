import Cookies from 'js-cookie'
import { UserInfo } from '@/types/system'
import { StudentSigning } from '@/types/teacherH5'

const TokenKey = 'token'
const RoleType = 'roleType'
const User = 'user'
const SignBaseInfo = 'signBaseInfo'
const GetDigitalBaseInfo = 'getDigitalBaseInfo'
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token: string) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getRoleType() {
  return Cookies.get(RoleType)
}

export function getUserInfo() {
  const userInfo = Cookies.get(User)
  return userInfo ? JSON.parse(userInfo) : ''
}

export function setUserInfo(user: UserInfo) {
  return Cookies.set(User, JSON.stringify(user))
}

export function removeUserInfo() {
  return Cookies.remove(User)
}

// 老师端-签到页面-当前活动和年级
export function getSignBaseInfo() {
  const signBaseInfo = localStorage.getItem(SignBaseInfo)
  return signBaseInfo ? JSON.parse(signBaseInfo) : ''
}

export function setSignBaseInfo(value: StudentSigning) {
  return localStorage.setItem(SignBaseInfo, JSON.stringify(value))
}

export function removeSignBaseInfo() {
  return localStorage.removeItem(SignBaseInfo)
}

// 老师端-数据采集页面-当前活动和年级
export function getDigitalBaseInfo() {
  const digitalBaseInfo = localStorage.getItem(GetDigitalBaseInfo)
  return digitalBaseInfo ? JSON.parse(digitalBaseInfo) : ''
}

export function setDigitalBaseInfo(value: StudentSigning) {
  return localStorage.setItem(GetDigitalBaseInfo, JSON.stringify(value))
}

export function removeDigitalBaseInfo() {
  return localStorage.removeItem(GetDigitalBaseInfo)
}
