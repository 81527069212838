/* eslint-disable no-bitwise */
/* eslint-disable no-mixed-operators */
import { message } from 'antd'
import { RuleObject } from 'antd/es/form'
import { ValueProps } from '@/components/UploadPicture'
import dayjs from 'dayjs'
import { NumConversion } from '@/constants'
import moment, { Moment } from 'moment'
import * as forge from 'node-forge'
import getEnv from './env'

export const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
}

// 校验 upload 组件
export const validatorFileList = (
  uploadingMsg: string = '上传中...',
  errorMsg: string = '上传失败',
) => (_: RuleObject, value: ValueProps) => {
  if (value) {
    return (value.every((item) => item.status === 'done')
      ? Promise.resolve()
      : Promise.reject(new Error(
        value.some((item) => item.status === 'uploading')
          ? uploadingMsg : errorMsg,
      ))
    )
  }
  return Promise.resolve()
}

/**
 * 生成文件名
 * 年月日 + 6位随机数 + 文件名
 */
export const generateFileName = (file: File) => [dayjs().format('YYYYMMDDHHmmss'), Math.random().toString().slice(-6), file.name].join('')

/**
 * 校验文件大小是否超2M
 */
export function beforeUpload(file: File) {
  // const isLt2M = file.size / 1024 / 1024 < 2
  // if (!isLt2M) {
  //   message.error('图片不得超过2MB!')
  // }
  // return isLt2M
  return true
}

/**
 * uuid
 */
export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

/**
 * 根据wrapId获取 type('AUDIO' | 'VIDEO') 的媒体
 */
export function getMediaElement(wrapId: string, type: string):
  HTMLVideoElement | HTMLAudioElement | null {
  const element = document.getElementById(wrapId)!.getElementsByTagName(type)[0]
  if (!element) {
    return null
  }
  const media = element as HTMLVideoElement | HTMLAudioElement
  if (media?.readyState > 0) {
    return media
  }
  return null
}

export function getQuery() {
  const search = `?${window.location.href.split('?')[1]}`
  if (search) {
    return search.substr(1).split('&')
      .reduce<{ [key: string]: string }>((acc, currentValue) => {
        const [key, value] = currentValue.split('=')
        acc[key] = value
        return acc
      }, {})
  }
  return {}
}

/**
 * 效验日期，不能选择未来的时间
 */

export function disabledDate(current: Moment) {
  return current && current > moment().endOf('day')
}

export function beforeUploadAudio(file: File) {
  const isLt2G = file.size / 1024 / 1024 / 1024 < 2
  if (!isLt2G) {
    message.error('音频单独大小不得超过2G!')
  }
  return isLt2G
}

// 下载
function downloadFileByBlob(blobUrl: string, filename: string) {
  const a = document.createElement('a')
  a.download = filename
  a.style.display = 'none'
  a.href = blobUrl
  // 触发点击
  document.body.appendChild(a)
  a.click()
  // 然后移除
  document.body.removeChild(a)
}
export function getBlob(url: string, fileName: string) {
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.onload = () => {
    const blob = xhr.response
    const blobUrl = window.URL.createObjectURL(blob)
    downloadFileByBlob(blobUrl, fileName)
  }
  xhr.send()
}

export function NumberConversion(key: string) {
  if (key?.length === 0) {
    return []
  }
  const values = key.split(',')
  return values.sort((v: string, g: string) => {
    const a: number = NumConversion.getKey(v.substr(0, v.length >= 4 ? 2 : 1))
    const b: number = NumConversion.getKey(g.substr(0, g.length >= 4 ? 2 : 1))
    return a - b
  })
}

export function labelValidator(_: any, value: string[]) {
  if (value?.length > 3) return Promise.reject(new Error('最多选择3个标签。。。'))
  return Promise.resolve()
}

export function secondsFormat(s: number) {
  const day = Math.floor(s / (24 * 3600)) // Math.floor()向下取整
  const hour = Math.floor((s - day * 24 * 3600) / 3600)
  const minute = Math.floor((s - day * 24 * 3600 - hour * 3600) / 60)
  const second = s - day * 24 * 3600 - hour * 3600 - minute * 60
  if (day) return `${day}天${hour}时${minute}分${second}秒`
  if (hour) return `${hour}时${minute}分${second}秒`
  if (minute) return `${minute}分${second}秒`
  if (second) return `${second}秒`
  return '0秒'
}

// 加密
export function handleEncryptId(schoolId: string) {
  const { pki, util } = forge
  const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDt30AcO8CSAfzSa5L8ikVrfehH6aFw9KyL85NzOAduOfnPcbiAGLjLWEKOkOhkYrlSAfU5s+pa3OQTsgpfCkVVm56dEQh8sajIR4uyGbhv0/CdvPTZS5o3sP6Yi9TemWZ443+QNjajN6MSCTmTY86ZoR9jmTcJtV4kNTQWDov6qQIDAQAB'
  // 规定格式：publicKey之前需要加'-----BEGIN PUBLIC KEY-----\n'，之后需要加'\n-----END PUBLIC KEY-----'
  const publicK = pki.publicKeyFromPem(`-----BEGIN PUBLIC KEY-----\n ${publicKey} \n-----END PUBLIC KEY-----`)
  return util.encode64(publicK.encrypt(schoolId))
}

// 判断是否是移动端
export function ismobile() {
  if (window.navigator.userAgent.match(/(phone|pad|pod|iphone|ipod|ios|ipad|android|mobile|blackberry|iemobile|mqqbrowser|juc|fennec|wosbrowser|browserng|webos|symbian|windows phone)/i)) {
    return true // 移动端
  }
  return false // pc端
}

// 是否是安卓系统
export function isAndroid() {
  return navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1
}

/**
 * 打开新窗口
 */
export const openInNewTab = (url: string) => {
  const win = window.open(url, '_blank')
  if (!win) {
    message.warning('请开启允许弹出式窗口')
  }
  win?.focus()
}

export function jsGetAge(strBirthday: any) {
  let returnAge
  const strBirthdayArr = strBirthday.split('-')
  const birthYear = strBirthdayArr[0]
  const birthMonth = strBirthdayArr[1]
  const birthDay = strBirthdayArr[2]
  const d = new Date()
  const nowYear = d.getFullYear()

  const nowMonth = d.getMonth() + 1

  const nowDay = d.getDate()

  if (`${nowYear}` === `${birthYear}`) {
    returnAge = 0// 同年 则为0岁
  } else {
    const ageDiff = nowYear - birthYear // 年之差
    if (ageDiff > 0) {
      if (`${nowMonth}` === `${birthMonth}`) {
        const dayDiff = nowDay - birthDay// 日之差
        if (dayDiff < 0) {
          returnAge = ageDiff - 1
        } else {
          returnAge = ageDiff
        }
      } else {
        const monthDiff = nowMonth - birthMonth// 月之差
        if (monthDiff < 0) {
          returnAge = ageDiff - 1
        } else {
          returnAge = ageDiff
        }
      }
    } else {
      returnAge = -1// 返回-1 表示出生日期输入错误 晚于今天
    }
  }

  return returnAge// 返回周岁年龄
}

// 判断是否为图片，主流图片
export function isAssetTypeAnImage(ext: string): boolean {
  return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff']
    .indexOf(ext.toLowerCase()) !== -1
}
export function isAssetTypeAnVideo(ext: string): boolean {
  return ['mp4', 'avi', 'rmvb', 'flv', '3gp', 'rm', 'mov']
    .indexOf(ext.toLowerCase()) !== -1
}

// 获取主机
export function getHost() {
  const env = getEnv()
  if (env === 'local') {
    return 'manageali.pbl.xiaoleader.com'
  }
  return encodeURIComponent(window.location.host)
}
