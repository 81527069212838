import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import 'dayjs/locale/zh-cn'
import 'moment/locale/zh-cn'
import { HashRouter } from 'react-router-dom'

import '@/styles/theme.less'
import '@/styles/iconfont/iconfont.css'
import App from './App'
import '@/styles/global.less'

const excludeClass = ['ant-tooltip-open', 'ant-popover-open']

ReactDOM.render(
  <ConfigProvider
    locale={zhCN}
    getPopupContainer={(triggerNode) => {
      if (triggerNode) {
        if (excludeClass.some((clas) => triggerNode.classList.contains(clas))) {
          return document.body
        }
        return triggerNode.parentNode as HTMLElement
      }
      return document.body
    }}
  >
    <HashRouter>
      <App />
    </HashRouter>
  </ConfigProvider>,
  document.getElementById('root'),
)
